import React from 'react';
import PropTypes from 'prop-types';
import logo from "../assets/svg/whatsapp-logo.svg"
import '../assets/styles/whatsapp.css'


const Whatsapp = ({ appContext}) => {

    return (
        <div className="principalWatsapp">
           {/* <a href={`https://api.whatsapp.com/send?phone=52${number}&text=Apoyo%20Economico%20Asistencia%20WhatsApp:%20${location}`} target="blank"><img src={logo} alt="whatsapp"/></a> */}
           <a href={`https://chatrouter-service.coa.tijuana.mesh-servicios-fnd.mx/v1/whatsapp/${appContext.companyName === "AEFALTOPERFIL" ? "AEF" : appContext.companyName}`} target="_blank"><img src={logo} alt="whatsapp"/></a> 
        </div>
    );
};


Whatsapp.propTypes = {
 number: PropTypes.string
};
Whatsapp.defaultProps = {
    number: '0000000000'
   };


export default Whatsapp;
